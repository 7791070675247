/* eslint-disable react/no-unknown-property */
import React, { FC } from 'react';
import classNames from 'classnames';
import { Link } from 'gatsby';

import IconCustom from 'common/IconCustom';

import { IBreadCrumbs } from './model';

import './BreadCrumbs.scss';
import './ShadowBreadCrumbs.scss';

const BreadCrumbs: FC<IBreadCrumbs> = ({ breadcrumbs, className }) => (
  <ul
    className={classNames('breadcrumbs', {
      [`${className}`]: className,
      'breadcrumbs-long': breadcrumbs.length > 2,
    })}
    data-test="BreadCrumb"
  >
    {breadcrumbs.map(({ name, link }, idx) => (
      <li key={`list-${name}`}>
        {link && breadcrumbs[idx + 1] ? (
          <Link className="breadcrumbs__link" to={link}>
            <span className="link__label">{name}</span>
            <IconCustom icon="arrow-long" className="link__icon" />
          </Link>
        ) : (
          <span className="link__label">{name}</span>
        )}
      </li>
    ))}
  </ul>
);

export default BreadCrumbs;
