import React, { FC, useMemo } from 'react';
import { graphql } from 'gatsby';

import { Layout, Container } from 'layout';
import BreadCrumbs from 'common/BreadCrumbs';
import SiteMapLink from 'components/SiteMapLink';

import { schemaService } from 'utils/schemaService';
import { filteredPages, linksParser } from 'utils/siteMap/helpers';

import { IPropsSiteMapPage } from './models';

import './SiteMapPage.scss';

const SiteMapPage: FC<IPropsSiteMapPage> = ({
  data: { pageData, allSitePage },
  pageContext: { breadcrumbs, link },
}) => {
  const {
    defaultCompositions,
    seo,
    title,
    symptomsTitle,
    findAdviceTitle,
    findRetailerTitle,
    strepsilsProductsTitle,
    otherPagesTitle,
    strepsilsBrandTitle,
    excludedPages,
    lang,
  } = pageData;

  const pages = useMemo(
    () => (lang ? filteredPages(allSitePage.nodes, excludedPages, lang) : []),
    [allSitePage.nodes, excludedPages, lang]
  );

  const {
    finalPageLinks: { products, advice, remained, symptoms, whereToBuy, whyStrepsils },
  } = linksParser(pages);

  return (
    <Layout
      seo={seo}
      defaultCompositions={defaultCompositions}
      additionalStructuredSchemas={[schemaService.schemaTypes.BreadcrumbList]}
      dynamicStructuredSchemaValues={{ pageUrl: link, breadcrumbs }}
    >
      <Container>
        <BreadCrumbs breadcrumbs={breadcrumbs} />
        <h1 className="sitemap__title">{title}</h1>
        <div className="sitemap__content">
          <div className="sitemap__block">
            {symptomsTitle && symptoms ? (
              <SiteMapLink title={symptomsTitle} linksArray={symptoms} />
            ) : null}
            {findAdviceTitle && advice ? (
              <SiteMapLink title={findAdviceTitle} linksArray={advice} />
            ) : null}
            {findRetailerTitle && whereToBuy ? (
              <SiteMapLink title={findRetailerTitle} linksArray={whereToBuy} />
            ) : null}
            {otherPagesTitle && remained ? (
              <SiteMapLink title={otherPagesTitle} linksArray={remained} />
            ) : null}
          </div>
          <div className="sitemap__block">
            {strepsilsProductsTitle && products ? (
              <SiteMapLink title={strepsilsProductsTitle} linksArray={products} />
            ) : null}
          </div>
          <div className="sitemap__block">
            {strepsilsBrandTitle && whyStrepsils ? (
              <SiteMapLink title={strepsilsBrandTitle} linksArray={whyStrepsils} />
            ) : null}
          </div>
        </div>
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query ($link: String!) {
    pageData: siteMap(link: { eq: $link }) {
      seo {
        ...FragmentSeo
      }
      defaultCompositions {
        ...FragmentDefaultCompositions
      }
      title
      findAdviceTitle
      findRetailerTitle
      symptomsTitle
      strepsilsProductsTitle
      strepsilsBrandTitle
      otherPagesTitle
      lang
      excludedPages {
        name
        url
      }
    }
    allSitePage {
      nodes {
        context {
          link
          pageName
          pageType
          lang
          isMainLang
        }
      }
    }
  }
`;

export default SiteMapPage;
