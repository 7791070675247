const BOOLEAN_NUM = {
  FALSY: '0',
  TRUTHY: '1',
};

const PAGE_TYPES = {
  products: 'products',
  symptoms: 'symptoms',
  whyStrepsils: 'whyStrepsils',
  advice: 'advice',
  whereToBuy: 'whereToBuy',
  remained: 'remained',
  homePage: 'homePage',
  signUp: 'signUp',
};

module.exports = {
  BOOLEAN_NUM,
  PAGE_TYPES,
};
